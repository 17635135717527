.carouselFirstBlock {
    margin-top: 100px;
    height: 86vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

/* ----------------SWIPER-------------------- */
swiper-container {
    width: 100%;
    height: 86vh;
}

.plantPhoto {
    width: 100%;
    height: 100%;
}

.pigsPhoto {
    height: 100%;
    width: 100%;
}

.shopPhoto {
    height: 100%;
    width: 100%;
}

/* ----------------RESOLUTION-------------------- */
@media (max-width: 767px) {
    .carouselFirstBlock {
        height: 50vi;
    }

    swiper-container {
        height: 50vi;
    }

    .carouselFirstBlock {
        margin-top: 45px;
    }

    .plantPhoto {
        width: 100vi;
        height: 50vi;
    }
    
    .pigsPhoto {
        height: 50vi;
        width: 100vi;
    }
    
    .shopPhoto {
        height: 50vi;
        width: 100vi;
    }
}