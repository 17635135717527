/* ----------------SCROLL-------------------- */
.underBlock {
    position: relative;
    height: calc(100vh - 100px);
    display: flex;
    align-items: center;
}

.underBlock::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    background-image: url('../../assets/top-view-blank-menu-with-plate-meat.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
}

.contactForm {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
}

.photoPig {
    height: 100px;
}

.scrollWrapper {
    cursor: pointer;
    height: 3vh;
    width: 6vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.scroll {
    height: 3vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.scrollArrow {
    height: 3vh;
}

/* ----------------FONTS-------------------- */
@font-face {
    font-family: 'Cartoon-regular';
    src: url('../../fonts/Cartoon1471Extended.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}

@media (max-width: 767px) {
    .scroll {
        display: none;
    }
}