.aboutUsBlock {
    margin: 0;
    padding: 0;
    height: calc(100vh - 100px);
}

.aboutUsBlock::before {
    content: "";
    position: absolute;
    width: 100%;
    height: calc(100vh - 100px);
    background-image: url('../../assets/wild-garlic-2160258_1920.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    opacity: 0.9;
    z-index: -1;
}

.aboutUsBlockWrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    height: calc(100vh - 100px);
    width: 40vi;
    margin: 0 auto;
    font-size: 10px;
    font-weight: 700;
    text-align: center;
}

.aboutUsBlockWrapper *::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-image: url('../../assets/111.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    opacity: 0.9;
    z-index: -1;
}

/* ----------------CONTENT-------------------- */
.text1 {
    height: 25%;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.text1_1 {
    display: flex;
    width: 50%;
    height: 50%;
    align-items: center;
    justify-content: center;
}

.text2 {
    height: 25%;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.text2_1 {
    display: flex;
    width: 50%;
    height: 50%;
    align-items: center;
    justify-content: center;
}

.text3 {
    height: 25%;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.text3_1 {
    display: flex;
    width: 50%;
    height: 50%;
    align-items: center;
    justify-content: center;
}

.text4 {
    height: 25%;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.text4_1 {
    display: flex;
    width: 50%;
    height: 50%;
    align-items: center;
    justify-content: center;
}

/* ----------------RESOLUTION-------------------- */

@media (max-width: 767px) {
    .aboutUsBlockWrapper {
        flex-wrap: unset;
        flex-direction: column;
    }

    .text2 {
        align-self: unset;
        margin-top: unset;
    }
    
    .text3 {
        align-self: unset;
        margin-top: unset;
    }
    
    .text4 {
        margin-top: unset;
    }
}

@media (min-width: 768px) {
    .aboutUsBlockWrapper {
        font-size: 18px;
    }

    .text1 {
        height: 50%;
        width: 50%;
    }

    .text2 {
        height: 50%;
        width: 50%;
    }
    
    .text3 {
        height: 50%;
        width: 50%;
    }
    
    .text4 {
        height: 50%;
        width: 50%;
    }
}

@media (min-width: 768px) and (max-width: 1365px) {
    .aboutUsBlockWrapper {
        font-size: 14px;
    }
}